import { serverURL, handleRequest } from '../helpers/server';
const querystring = require('querystring');
const crypto = require('crypto');

export const userService = {
  login,
  logout,
  userAdminInfo,
  userInfo,
  userCredit,
  bonusRegister,
  update,
  creditsInfo,
  creditsApiInfo,
  register,
  corporateRegister,
  corporateUpdate,
  corporateInfo,
  groupRegister,
  groupUpdate,
  groupRemove,
  groupInfo,
  remove,
  updateAdmin,
  redeemCode,
  requestNewPassowrd,
  submitNewPass,
  authSocialLogin,
  getLoginSocialInfo,
  socialLogin,
  audits,
  statementCreditInfo,
  statementUserCreditInfo,
  tokenRegister,
  tokenRemove,
  tokens
};

async function login (login, password) {
  const requestOptionsLogin = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      login
    })
  };
  const end = `${serverURL()}/auth/v1/login`;
  const res = await fetch(end, requestOptionsLogin);
  if (res.status === 403) {
    throw new Error('Usuário não existe no banco de dados.');
  }
  const sha512 = data => crypto.createHash('sha512').update(data).digest('hex');
  const {
    challenge,
    salt
  } = await res.json();

  const requestOptionsChallenge = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      login,
      challenge,
      response: sha512(challenge + sha512(login + password) + salt)
    })
  };

  return await fetch(`${serverURL()}/auth/v1/challenge`, requestOptionsChallenge)
    .then(async res => {
      if (res.status === 404) {
        throw new Error('Usuário não existe');
      }
      const user = await res.json();
      user.username = login;
      document.cookie = user.token;
      localStorage.setItem('user', JSON.stringify(user));
    });
}

async function socialLogin (userData) {
  const user = {
    username: userData.user.login,
    token: userData.token
  };
  document.cookie = userData.token;
  localStorage.setItem('user', JSON.stringify(user));
}

async function authSocialLogin (provider) {
  const callbackUrl = Buffer.from(serverURL(), 'utf8').toString('base64');
  const end = `${serverURL()}/auth/v1/${provider}?callback_url=${callbackUrl}`;
  window.location.href = end;
}

function logout () {
  localStorage.removeItem('user');
  document.cookie = 'access_token=; Max-Age=-99999999;';
}

async function remove (login, removeAll) {
  const requestOptions = {
    method: 'DELETE',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ removeAll }),
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/user/${login}`, requestOptions);
}

async function userAdminInfo (params) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/user/admin/info?${querystring.stringify(params)}`, requestOptions);
}

async function corporateInfo (params) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/corporate/admin/info?${querystring.stringify(params)}`, requestOptions);
}

async function userInfo () {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/user/info`, requestOptions);
}

async function userCredit (login) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/user/${login}/credits`, requestOptions);
}

async function creditsInfo () {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/user/credits/info`, requestOptions);
}

async function creditsApiInfo () {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/user/api/credits/info`, requestOptions);
}

async function redeemCode (code) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    timeout: 30000
  };

  const res = await fetch(`${serverURL()}/portal/v1/user/redeem/${code}`, requestOptions);
  return await res.json();
}

async function groupInfo (groupId) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/group/info/${groupId}`, requestOptions);
}

async function updateAdmin (login) {
  const requestOptions = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(login),
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/user/admin`, requestOptions);
}

async function update (userInfo) {
  const requestOptions = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userInfo),
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/user`, requestOptions);
}

async function register (userInfo) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userInfo),
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/user/${userInfo.login}`, requestOptions);
}

async function bonusRegister (data) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/bonus`, requestOptions);
}

async function corporateUpdate (corporateInfo) {
  const requestOptions = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(corporateInfo)
  };
  return await handleRequest(`${serverURL()}/portal/v1/corporate`, requestOptions);
}

async function corporateRegister (corporateInfo) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(corporateInfo)
  };
  return fetch(`${serverURL()}/portal/v1/corporate`, requestOptions).then(handleResponse);
}

async function groupRegister (groupInfo) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(groupInfo),
    timeout: 30000
  };

  return await handleRequest(`${serverURL()}/portal/v1/group`, requestOptions);
}

async function groupUpdate (groupInfo) {
  const requestOptions = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      timeout: 30000
    },
    body: JSON.stringify(groupInfo)
  };
  return await handleRequest(`${serverURL()}/portal/v1/group/${groupInfo.id}`, requestOptions);
}

async function groupRemove (id) {
  const requestOptions = {
    method: 'DELETE',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/group/${id}`, requestOptions);
}

async function audits (params) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/user/audit?${querystring.stringify(params)}`, requestOptions);
}

async function requestNewPassowrd (email) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(email),
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/user/password/request`, requestOptions);
}

async function submitNewPass (userData) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userData),
    timeout: 30000
  };
  return await fetch(`${serverURL()}/portal/v1/user/password/redeem`, requestOptions);
}

async function getLoginSocialInfo (userData) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userData),
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/socialLogin`, requestOptions);
}

async function statementCreditInfo () {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/user/statementCredit`, requestOptions);
}

async function statementUserCreditInfo (id, params) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/user/${id}/statementCredit?${querystring.stringify(params)}`, requestOptions);
}

async function tokenRegister (tokenName) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(tokenName),
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/token`, requestOptions);
}

async function tokenRemove (id) {
  const requestOptions = {
    method: 'DELETE',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/token/${id}`, requestOptions);
}

async function tokens () {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    timeout: 30000
  };
  return await handleRequest(`${serverURL()}/portal/v1/token`, requestOptions);
}

function handleResponse (response) {
  if (response.timeout) {
    return 'Timeout';
  }
  if (response.status === 403) {
    return 'Forbidden';
  }
  const text = response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
        location.reload(true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
  return text;
}
