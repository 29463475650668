import Vue from 'vue';
import Vuex from 'vuex';

import { account } from './account';
import { alert } from './alert';
import { editor } from './editor';
import { transcription } from './transcription';
import { authorization } from './authorization';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    account,
    alert,
    editor,
    transcription,
    authorization
  }
});
