import { transcription } from '../store/transcription';
import { serverURL, fetchWithTimeout } from '../helpers/server';
const querystring = require('querystring');

export const transcriptionService = {
  registerTranscription,
  getMetadataTranscriptions,
  getTranscription,
  uploadMedia,
  deleteTranscription,
  uploadFileWithXHR,
  uploadFileWithFetch,
  rating
};

async function getMetadataTranscriptions (params) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    timeout: 6000
  };
  const res = await fetchWithTimeout(`${serverURL()}/stt/v1/transcripts/metadata?${querystring.stringify(params)}`, requestOptions);
  return await res.json();
}

async function getTranscription (id) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const user = JSON.parse(localStorage.getItem('user'));
  const res = await fetchWithTimeout(`${serverURL()}/stt/v1/transcript/${id}/transcription?cookie=${user.token}`, requestOptions);
  return await res.json();
}

// TODO: Refactor authentication
async function registerTranscription (transcript) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    mode: 'cors',
    body: JSON.stringify(transcript)
  };
  const user = JSON.parse(localStorage.getItem('user'));
  return await fetch(`${serverURL()}/stt/v1/transcript?cookie=${user.token}`, requestOptions);
}
// TODO: Refactor authentication
async function uploadMedia (id, file, valId) {
  const user = JSON.parse(localStorage.getItem('user'));
  const url = `${serverURL()}/stt/v1/transcript/${id}/media?cookie=${user.token}`;
  const formData = new FormData();
  formData.append('file', file, 'name');
  transcription.state.percentCompleted = 0;
  return await this.uploadFileWithXHR(formData, url, file);
}

async function uploadFileWithFetch (data, url, file) {
  const requestUploadOptions = {
    method: 'POST',
    body: file
  };
  await fetch(url, requestUploadOptions)
    .then(
      success => console.log(success) // Handle the success response object
    )
    .catch(
      error => console.log(error) // Handle the error response object
    );
}

function uploadFileWithXHR (data, url, file) {
  return new Promise(function (resolve, reject) {
    const request = new XMLHttpRequest();
    request.open('POST', url);

    request.upload.addEventListener('progress', function (e) {
      const percentCompleted = (e.loaded / e.total) * 100;
      transcription.state.percentCompleted = percentCompleted;
      console.log(percentCompleted);
    });
    request.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(request.response);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          status: this.status,
          statusText: request.statusText
        });
      }
    };
    request.onerror = function () {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({
        status: this.status,
        statusText: request.statusText
      });
    };
    request.send(file);
  });
}

async function deleteTranscription (id) {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    timeout: 6000
  };
  const user = JSON.parse(localStorage.getItem('user'));
  return await fetch(`${serverURL()}/stt/v1/transcript/${id}?cookie=${user.token}`, requestOptions);
}

async function rating (transcriptionId, comments, rating) {
  const requestOptions = {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(comments, rating),
    timeout: 6000
  };
  return await fetchWithTimeout(`${serverURL()}/stt/v1/transcript/${transcriptionId}/score`, requestOptions);
}
