import { transcriptionService } from '../services/transcription.service';

const state = {
  status: {},
  transcriptions: [],
  percentCompleted: 0
};

const actions = {
  register ({ dispatch, commit }, transcription) {
    commit('registerRequest', transcription);
    transcriptionService.registerTranscription(transcription).then(
      res => {
        transcriptionService.uploadMedia(transcription, res).then(() => {
          commit('registerSuccess');
          setTimeout(() => {
            dispatch('alert/success', 'Registration successful', { root: true });
          });
        }, error => {
          commit('registerFailure', error);
          dispatch('alert/error', error, { root: true });
        }
        );
      },
      error => {
        commit('registerFailure', error);
        dispatch('alert/error', error, { root: true });
      }
    );
  },
  transcriptions ({ dispatch, commit }) {
    transcriptionService.getTranscriptions().then(
      transcriptions => {
        commit('transcriptionSuccess', transcriptions);
      },
      error => {
        dispatch('alert/error', error, { root: true });
      }
    );
  }
};

const mutations = {
  registerRequest (state /* transcription */) {
    state.status = { registering: true };
  },
  registerSuccess (state /* transcription */) {
    state.status = {};
  },
  registerFailure (state /* transcription */) {
    state.status = {};
  },
  transcriptionSuccess (state, transcriptions) {
    state.transcriptions = transcriptions;
  }

};

export const transcription = {
  namespaced: true,
  state,
  actions,
  mutations
}
;
