import { serverURL, handleRequest } from '../helpers/index';

const _defaultPermissions = {
  SOLICITAR_TRANSCRICAO: 'Solicitar transcrição',
  EDITAR_USUARIO: 'Editar usuário',
  CRIAR_USUARIO: 'Criar usuário',
  EXCLUIR_USUARIO: 'Excluir usuário',
  ACESSAR_VIA_APLICATIVO: 'Acessar via aplicativo'
};

export const authorizationService = {
  permissions,
  userInfo,
  defaultPermissions
};

function defaultPermissions () {
  return _defaultPermissions;
}

async function permissions () {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      credentials: 'include',
      timeout: 6000
    }
  };
  return await handleRequest(`${serverURL()}/authorization/v1/user/permissions`, requestOptions);
}

async function userInfo (login) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      timeout: 6000
    }
  };
  return await handleRequest(`${serverURL()}/authorization/v1/user/${login}`, requestOptions);
}
