import { authorizationService } from '../services/authorization.service';

const state = {
  status: {},
  permissions: [],
  roles: [],
  user_permissions: [],
  user_roles: []
};

const actions = {
  registerPermission ({ dispatch, commit }, permission) {
    commit('registerRequest', permission);
    authorizationService.addPermission(permission).then(
      () => {
        commit('registerPermissionSuccess', permission);
        setTimeout(() => {
          dispatch('alert/success', 'Registration successful', { root: true });
        });
      },
      error => {
        commit('registerFailure', error);
        dispatch('alert/error', error, { root: true });
      }
    );
  },
  userInfo ({ dispatch, commit }, login) {
    authorizationService.userInfo(login).then(
      info => {
        const roles = [];
        const done = new Promise((resolve, reject) => {
          info.roles.forEach(async (value, index, array) => {
            await authorizationService.getRole(value).then(role => {
              roles.push(role);
            });
            if (index === array.length - 1) {
              resolve();
            }
          });
        });

        done.then(() => {
          info.roles = roles;
          commit('userInfoSuccess', info);
        });
      },
      error => {
        dispatch('alert/error', error, { root: true });
      }
    );
  },
  permissions ({ dispatch, commit }) {
    authorizationService.getPermissions().then(
      permissions => {
        commit('updatePermission', permissions);
      },
      error => {
        dispatch('alert/error', error, { root: true });
      }
    );
  },
  roles ({ dispatch, commit }) {
    authorizationService.getRoles().then(
      roles => {
        commit('updateRoles', roles);
      },
      error => {
        dispatch('alert/error', error, { root: true });
      }
    );
  },
  role ({ dispatch, commit }, login) {
    authorizationService.getRole(login).then(
      roles => {
        commit('user', roles);
      },
      error => {
        dispatch('alert/error', error, { root: true });
      }
    );
  },
  registerRole ({ dispatch, commit }, role) {
    commit('registerRequest', role);
    authorizationService.addRole(role).then(
      () => {
        commit('registerRoleSuccess', role);
        setTimeout(() => {
          dispatch('alert/success', 'Registration successful', { root: true });
        });
      },
      error => {
        commit('registerFailure', error);
        dispatch('alert/error', error, { root: true });
      }
    );
  },
  updateRole ({ dispatch, commit }, groupAssociation) {
    authorizationService.updateRole(groupAssociation).then(
      () => {
        setTimeout(() => {
          dispatch('alert/success', 'Update successful', { root: true });
        });
      },
      error => {
        dispatch('alert/error', error, { root: true });
      }
    );
  },
  updateUserRole ({ dispatch, commit }, content) {
    console.log(content);
    authorizationService.updateUserRole(content).then(
      () => {
        setTimeout(() => {
          dispatch('alert/success', 'Update successful', { root: true });
        });
      },
      error => {
        dispatch('alert/error', error, { root: true });
      }
    );
  }
};

const mutations = {
  registerRequest (state /* permission */) {
    state.status = { registering: true };
  },
  registerPermissionSuccess (state, permission) {
    state.status = {};
    state.permissions.push(permission);
  },
  registerRoleSuccess (state, role) {
    state.status = {};
    state.roles.push(role);
  },
  registerFailure (state /* user */) {
    state.status = {};
  },
  updatePermission (state, permissions) {
    state.permissions = permissions;
  },
  updateRoles (state, roles) {
    state.roles = roles;
  },
  usersRequest (state) {

  },
  usersSuccess (state, users) {
    state.users = users;
  },
  userInfoSuccess (state, info) {
    state.user_permissions = info.permissions;
    state.user_roles = info.roles;
  }
};

export const authorization = {
  namespaced: true,
  state,
  actions,
  mutations
}
;
