import Vue from 'vue';
import Router from 'vue-router';
import { userService } from '../services/user_service';
Vue.use(Router);

const Login = () => import('../components/Login_');
const Home = () => import('../components/Home');
const SignIn = () => import('../components/SignIn.vue');
const Transcriptions = () => import('../components/Transcriptions');
const Users = () => import('../components/Users');
const Groups = () => import('../components/Groups');
const Audit = () => import('../components/Audit');
const StorePlans = () => import('../components/Store/StorePlans/StorePlans');
const StoreDetached = () => import('../components/Store/StoreDetached/StoreDetached.vue');
const StoreExtract = () => import('../components/Store/StoreExtract');
const TranscriptionEditor = () => import('../components/TranscriptionEditor');
const Profile = () => import('../components/Profile');
const UserRegister = () => import('../components/UserRegister');
const CorporateRegister = () => import('../components/CorporateRegister');
const Admin = () => import('../components/Admin');
const ChangePassword = () => import('../components/ChangePassword.vue');
const AdvancedContact = () => import('../components/Store/AdvancedPlan.vue');
const SucessfulPurchase = () => import('../components/Store/SuccessfulPurchase.vue');
const DownloadApp = () => import('../components/DownloadApp.vue');

const routerMap = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/transcriptions',
    name: 'transcriptions',
    component: Transcriptions
  },
  {
    path: '/users',
    name: 'users',
    component: Users
  },
  {
    path: '/groups',
    name: 'Groups',
    component: Groups
  },
  {
    path: '/store/plans',
    name: 'StorePlans',
    component: StorePlans
  },
  {
    path: '/store/detached',
    name: 'StoreDetached',
    component: StoreDetached
  },
  {
    path: '/store/extract',
    name: 'StoreExtract',
    component: StoreExtract
  },
  {
    path: '/audit',
    name: 'Audit',
    component: Audit
  },
  {
    path: '/Editor',
    name: 'Editor',
    component: TranscriptionEditor
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/UserRegister',
    name: 'UserRegister',
    component: UserRegister
  },
  {
    path: '/CorporateRegister',
    name: 'CorporateRegister',
    component: CorporateRegister
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/store/advanced-contact',
    name: 'AdvancedContact',
    component: AdvancedContact
  },
  {
    path: '/store/sucessful-purchase',
    name: 'sucessfulPurchase',
    component: SucessfulPurchase
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: ChangePassword
  },
  {
    path: '/signin',
    name: 'signin',
    component: SignIn
  },
  {
    path: '/download',
    name: 'download',
    component: DownloadApp
  }
];

const router = new Router({
  routes: routerMap
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login', '/userRegister', '/changePassword', '/signin'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  if (to.path === '/userRegister' || to.path === '/changePassword') {
    userService.logout();
  }

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
});

export default router;
