import router from '../helpers/router';
import { formatingSecondToDate } from '../helpers/server';
import { userService } from '../services/user_service';

const user = JSON.parse(localStorage.getItem('user'));
const state = user
  ? {
    status: { loggedIn: true },
    user,
    permissions: [],
    isAdmin: false,
    corporateId: null,
    roles: [],
    users: [],
    statusMenu: false,
    master: false,
    errorLogin: false,
    userExistInDb: true,
    creditsAvailable: '00:00:00',
    creditsApiAvailable: '00:00:00',
    reserved: {},
    creditsTotal: {},
    reservedApi: {},
    creditsApiTotal: {}
  }
  : {
    status: {},
    user: null,
    isAdmin: false,
    corporateId: null,
    permissions: [],
    roles: [],
    users: [],
    statusMenu: false,
    master: false,
    errorLogin: false,
    userExistInDb: true,
    creditsAvailable: '00:00:00',
    creditsApiAvailable: '00:00:00',
    reserved: {},
    creditsTotal: {},
    reservedApi: {},
    creditsApiTotal: {}
  };

const creditsAvailable = (balance) => {
  const creditsTotal = Number(balance.credits);
  const reservedCredits = Number(balance.reservado);

  const creditsAvailable = creditsTotal - reservedCredits;
  const availableFormatedToDisplay = formatingSecondToDate(creditsAvailable);
  const totalFormaterToDisplay = formatingSecondToDate(creditsTotal);
  const reserved = new Date(balance.reservado * 1000).toISOString().substr(11, 8);
  return { availableFormatedToDisplay, totalFormaterToDisplay, reserved };
};

const actions = {
  async login ({ dispatch, commit }, { user, password }) {
    commit('loginRequest', { user });
    await userService.login(user, password).then(async () => {
      commit('loginSuccess', user, []);
      router.push('/home');
    },
    error => {
      if (error.message === 'Usuário não existe no banco de dados.') {
        commit('userNotFoundFailure', error);
      }
      commit('loginFailure', error);
      setTimeout(() => {
        dispatch('alert/error', error, { root: true });
      });
    }
    );
  },
  logout ({ commit }) {
    userService.logout();
    commit('logout');
  },
  update ({ commit }, { master, corporateId, permissions, isAdmin }) {
    commit('updateUser', { master, corporateId, permissions, isAdmin });
  },
  balance ({ commit }) {
    userService.creditsInfo().then(
      balance => {
        commit('balance', creditsAvailable(balance));
      }
    );
    userService.creditsApiInfo().then(
      balance => {
        commit('balanceApi', creditsAvailable(balance));
      }
    );
  },
  socialLogin ({ dispatch, commit }, { userDataSocialLogin }) {
    commit('loginRequest', { userDataSocialLogin });
    userService.socialLogin(userDataSocialLogin).then(async () => {
      commit('loginSuccess', user, []);
      router.push('/');
    },
    error => {
      commit('loginFailure', error);
      setTimeout(() => {
        dispatch('alert/error', error, { root: true });
      });
    }
    );
  }
};

const mutations = {
  loginRequest (state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  editRequest (state, user) {
    state.status = {};
  },
  editSuccess (state, user) {
    state.status = {};
  },
  loginSuccess (state, permissions) {
    state.status = { loggedIn: true };
    state.user = JSON.parse(localStorage.getItem('user'));
    state.permissions = permissions;
    state.user.permissions = permissions;
    localStorage.setItem('user', JSON.stringify(state.user));
  },
  loginFailure (state) {
    if (!state.userExistInDb) {
      state.errorLogin = false;
    } else {
      state.errorLogin = true;
    }
  },
  userNotFoundFailure (state) {
    state.userExistInDb = false;
  },
  logout (state) {
    state.status = { loggedIn: false }; ;
    state.user = null;
  },
  updateUser (state, creditsAvailable) {
    state.master = creditsAvailable.master;
    state.corporateId = creditsAvailable.corporateId;
    state.permissions = creditsAvailable.permissions;
    state.isAdmin = creditsAvailable.isAdmin;
  },
  balance (state, balance) {
    state.creditsAvailable = balance.availableFormatedToDisplay;
    state.reserved = balance.reserved;
    state.creditsTotal = balance.totalFormaterToDisplay;
  },
  balanceApi (state, balance) {
    state.creditsApiAvailable = balance.availableFormatedToDisplay;
    state.reservedApi = balance.reserved;
    state.creditsApiTotal = balance.totalFormaterToDisplay;
  }
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations
}
;
