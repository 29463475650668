<template>
  <nav>
    <b-navbar fixed="top" >
      <b-nav pills class="balance">
          <b-link class="link font-weight-bold" style="font-size: 15px" id="size"><span @click="showModal()">Saldo</span></b-link>
          <b-modal
          id="modal-prevent-closing"
          ref="modal"
          modal-class="edit-title"
          :title="modalTitle"
          hide-footer>
          <template>
              <div class="template">
                  <b-row cols="3" cols-sm="3" cols-md="4" cols-lg="3">
                      <b-col class="creditsBalance" style="margin-left=20px"></b-col> <b-col class="mb-2 creditsBalance">Aplicativo </b-col><b-col class="creditsBalance">API</b-col>
                      <b-col class="creditsBalance">Total </b-col> <b-col class="creditsBalanceRight">{{ account.creditsTotal }}</b-col><b-col class="creditsBalanceRight">{{ account.creditsApiTotal }}</b-col>
                      <b-col class="creditsBalance">Reservado </b-col> <b-col class="creditsBalanceRight">{{ account.reserved }}</b-col><b-col class="creditsBalanceRight">{{ account.reservedApi }}</b-col>
                      <b-col class="creditsBalance">Disponível </b-col> <b-col class="creditsBalanceRight">{{ account.creditsAvailable }}</b-col><b-col class="creditsBalanceRight">{{ account.creditsApiAvailable }}</b-col>
                  </b-row>
              </div>
              <b-table class="text-primary" :fields="statementsCreditFields" :items="statementsCreditItems" responsive striped style="{ max-height: 300px !important; height: 300px !important; overflow-y: scroll}"></b-table>
            </template>
            <footer>
                <b-button class="btn btn-primary" @click="hideModal()">Fechar</b-button>
            </footer>
          </b-modal>
          <b-link class="link" style="font-size: 12px"><span class="text_balance" @click="showModal()">Visualizar</span></b-link>
          </b-nav>
          <div class="logged-user" >
          <b-nav pills>
              <b-nav-item-dropdown right class="drop-menu">
                  <template slot="button-content">
                      <img src="@/assets/icons/account-circle.svg" />

                      <span class="user-name">{{
                        '     ' + this.$store.state.account.user.username }}
                      </span>
                  </template>
              <b-dropdown-item v-if="account.master" id="admin-item" @click="$router.push('/admin')">
                  <div class="drop-menu-items">
                    <img
                            id="profile-button"
                            src="@/assets/icons/admin_panel_settings_black_24dp.svg"
                    />
                    <p style="font-family: Poppins; font-size: 14px; font-weight: 600; color: #6278A3; padding-top: 16px; padding-left: 10px"> Admin</p>
                  </div>
                </b-dropdown-item>
              <b-dropdown-item  class="drop-menu-items" @click="$router.push('/profile')">
                    <div class="drop-menu-items">
                        <img
                        id="profile-button"
                          src="@/assets/icons/manage_accounts_black_24dp.svg"
                        />
                        <p style="font-family: Poppins; font-size: 14px; font-weight: 600; color: #6278A3; padding-top: 16px; padding-left: 10px"> Conta</p>
                    </div>
                </b-dropdown-item>
              <b-dropdown-item class="drop-menu-items" @click="$router.push('/login')">
                  <div class="drop-menu-items">
                      <img
                        id="exit-button"
                        src="@/assets/icons/u_sign-out-alt.svg"
                        style="padding-right: 15px; width: 48px; "
                      />
                      <p style="font-family: Poppins; font-size: 14px; font-weight: 600; color: #6278A3; padding-top: 16px; padding-left: 5px"> Sair</p>
                    </div>
                </b-dropdown-item>
              </b-nav-item-dropdown>
        </b-nav>
      </div>
    </b-navbar>
  </nav>
  </template>

<script>
  import { mapActions, mapState } from 'vuex';
  import { userService } from '../services/user_service';
  import { formatingSecondToDate } from '../helpers/server';
  import moment from 'moment';
  export default {
    name: 'Header',
    data () {
      return {
        statementsCreditItems: [],
        statementsCreditFields: [
          {
            key: 'mediaLength',
            label: 'Saldo',
            formatter: (mediaLength) => {
              const saldo = formatingSecondToDate(mediaLength);
              return saldo;
            }
          },
          {
            key: 'observation',
            label: 'Descrição'
          },
          {
            key: 'expireIn',
            label: 'Vencimento',
            formatter: (value, key, item) => {
              const n = new Date(value).getTime();
              if (!value) {
                return 'Sem validade';
              } else if (!isNaN(parseFloat(n)) && isFinite(n)) {
                return moment(value).format('DD/MM/YYYY');
              } else {
                return moment.unix(value / 1000).format('DD/MM/YYYY');
              }
            }
          }
        ],
        modalTitle: 'Saldo'
      };
    },
    computed: {
      ...mapState({
        account: (state) => state.account
      })
    },
    mounted () {
      this.refreshBalance();
    },
    methods: {
      ...mapActions('account', ['balance']),
      async refreshBalance () {
        if (this.$store.state.account.user.username !== '') {
          await this.balance();
        }
      },
      async showModal () {
        this.statementsCreditItems = await userService.statementCreditInfo();
        this.modalTitle = 'Saldo';
        this.$refs.modal.show();
      },
      async hideModal () {
        this.$refs.modal.hide();
      }
    }
  };
  </script>

<style lang="scss" scoped>

a {
  color: white;
  font-family: Poppins;
  font-size: 14px;
}

nav {
  width: 100%;
  height: 70px;
  left: 200px;
  padding-right: 210px;
  background: #004a78;
  color: #f5f5f5;
  justify-content: right;

  /deep/ .drop-menu-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  /deep/ .dropdown-menu .dropdown-menu-right {
    font-family: Poppins;
    font-weight: 600;
    font-style: normal;
    background: #ffffff;
    min-width: 2rem !important;
    max-height: 3rem !important;
  }

  .modal-title {
    color: #004a78 !important;
  }

  .modal-body {
    color: #004a78 !important;
  }

  .logged-user {
    img {
      width: 42px;
      height: 42px;
    }

    /deep/ .drop-menu > .dropdown-menu {
      border-radius: 8px !important;
      margin-top: -15px;
      padding-top: 0px;
    }

    .user-name {
      font-family: Poppins;
      font-weight: 600;
      font-style: normal;
      font-size: 16px;
      color: #f5f5f5;
    }

    .user-name,
    .user-role {
      line-height: 1;
    }

    #exit-button {
      cursor: pointer;
    }
    #profile-button {
      cursor: pointer;
    }
  }

  .nav-right {
    display: flex;
  }

  .logo-container {
    display: inline-block;
    align-self: flex-end;
    width: 35px;
    height: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: left !important;
  }
}

.headerMenu {
  float: left;
  left: 0;
  position: relative;
}

.balance {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-right: 3px;
  justify-content: right !important;
}

.new-user-button {
  margin-left: 83%;
  background: #207BDD;
  border-radius: 4px;
  border-color: #207BDD;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;

        &:hover {
            background-color: #b5dcb1;
            transition: background-color 0.2s linear;
          }
      }
#size {
  font-size: 12px;
}

.link:hover {
  text-decoration: none;
  color: #6278a3;
  transition: 0.3s;
}

p {
  color: #6278a3;
  margin-left: 60px;
  line-height: 10px;
  font-size: 14px;
  font-family: Poppins;
}
.creditsBalance {
  text-align: left;
  color: #6278A3;
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
}

.creditsBalanceRight {
  color: #6278A3;
  font-weight: 400;
  font-size: 14px;
}
.template {
  margin-bottom: 10px;
  margin-top: -10px;
}

.btn-primary {
    background-color: #207BDD;
    border: none;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    font-family: Poppins;
    margin-left: 313px;
  }

.text-primary {
  border-top: 1px solid rgb(221, 220, 220);
  color: #6278A3 !important;
}

</style>
