import Vue from 'vue';
import App from './App.vue';
import { configureBackend } from './helpers/index';
import VueMask from 'v-mask';
import AsyncComputed from 'vue-async-computed';
import router from './helpers/router';
import { store } from './store/index';
import VeeValidate from 'vee-validate';
import Vuelidate from 'vuelidate';
import VueSidebarMenu from 'vue-sidebar-menu';
import VueToast from 'vue-toast-notification';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import LoadScript from 'vue-plugin-load-script';
import VueClipboard from 'vue-clipboard2';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import './helpers/easter-egg/konami-code';
import VueCompositionApi from '@vue/composition-api';

import VueHtml2pdf from 'vue-html2pdf';
Vue.use(VueHtml2pdf);

Vue.use(VueCompositionApi);
Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(AsyncComputed);
Vue.use(LoadScript);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.config.productionTip = false;
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields'
});
Vue.use(VueSidebarMenu);
Vue.use(VueToast);
Vue.use(VueClipboard);
if (process.env.NODE_ENV === 'mock') {
  configureBackend();
}

new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App)
});
