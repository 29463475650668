<template>
  <main>
    <Header v-if="account.status.loggedIn" />
    <div id="menu" :class="[{'collapsed' : collapsed}]">
      <router-view />
      <sidebar-menu
        v-if="account.status.loggedIn"
        :menu="menu"
        :hideToggle="hideToogle"
        :collapsed="collapsed"
        @toggle-collapse="onToggleCollapse"
        @item-click="onItemClick"
        :width='width'
      >
      <template v-slot:header>
      <div class="headerMenu">
        <img src="@/assets/images/LogoNovo-Retextoar-branco.png" class="logo-container"/>
        <b-link :to="{path: '/home' }" class="title-style"/>
      </div>
      </template>
        <template v-slot:footer>
          <div id="armored_website">
            <param id="aw_preload" value="true" />
            <param id="aw_use_cdn" value="true" />
          </div>
          <div>
            <img src="@/assets/icons/Logo-Dgt-Rodape.svg" width="55px" height="55px" style="{ display: inline-block; margin-bottom: 5px; margin-left: 20px !important; }">
            <b-label style="{font-family: Poppins; font-size: 12px; font-weight: 600; color: #e0e0e2 !important; margin-left: 30px !important;}">Ver.{{version}}</b-label>
          </div>

        </template>
        <template v-slot:dropdown-icon>
          <img src="@/assets/icons/arrow-left.svg" width="15px" height="15px" style="transform:rotate(270deg);">
        </template>
        <!-- <span slot="toggle-icon"><img src="@/assets/icons/arrows-alt-h.svg"></span> -->
        <div v-if="this.$store.state.alert.hasError">
          <div>{{showErrorMessage()}}</div>
        </div>
      </sidebar-menu>
    </div>
  </main>
</template>

<script>
  import Header from './components/Header';
  import { userService } from './services/user_service';
  import userIcon from '@/assets/icons/account-multiple.svg';
  import scrollIcon from '@/assets/icons/scroll-solid.svg';
  import creditIcon from '@/assets/icons/credit.svg';
  import historyIcon from '@/assets/icons/audit.svg';
  import helpIcon from '@/assets/icons/question-circle-solid.svg';
  import groupsIcon from '@/assets/icons/users-solid.svg';
  import fileContractIcon from '@/assets/icons/plan.svg';
  import cashRegisterIcon from '@/assets/icons/alone.svg';
  import fileExtractIcon from '@/assets/icons/file-alt-solid.svg';
  import downloadAppIcon from '@/assets/icons/download-app.svg';
  import homeIcon from '@/assets/icons/home.svg';
  import { mapState, mapActions } from 'vuex';

  export default {
    name: 'app',
    data () {
      return {
        isMenuLoaded: false,
        collapsed: false,
        hideToogle: true,
        version: 'dev',
        title: 'Retextoar',
        width: '200px',
        mensagem: 'Erro!',
        menu: [],
        dashboard: {
          href: { path: '/' },
          title: 'Dashboard',
          icon: 'fas fa-columns',
          hidden: false
        },
        users: {
          href: { path: '/users' },
          title: 'Usuários',
          icon: {
            element: 'img',
            attributes: {
              src: userIcon
            }
          },
          hidden: false
        },
        home: {
          href: { path: '/home' },
          title: 'Início',
          icon: {
            element: 'img',
            attributes: {
              src: homeIcon
            }
          },
          hidden: false
        },
        transcriptions: {
          href: { path: '/transcriptions' },
          title: 'Transcrições',
          icon: {
            element: 'img',
            attributes: {
              src: scrollIcon
            }
          },
          hidden: false
        },
        audit: {
          href: { path: '/audit' },
          title: 'Auditoria',
          icon: {
            element: 'img',
            attributes: {
              src: historyIcon
            }
          },
          hidden: false
        },
        download: {
          href: { path: '/download' },
          title: 'Download',
          icon: {
            element: 'img',
            attributes: {
              src: downloadAppIcon
            }
          },
          hidden: false
        },
        groups: {
          href: { path: '/groups' },
          title: 'Grupos',
          icon: {
            element: 'img',
            attributes: {
              src: groupsIcon
            }
          },
          hidden: false
        },
        credits: {
          title: 'Créditos',
          child: [
            {
              href: '/store/plans',
              title: 'Planos',
              icon: {
                element: 'img',
                attributes: {
                  width: 24,
                  height: 24,
                  src: fileContractIcon
                }
              }
            },
            {
              href: '/store/detached',
              title: 'Avulso',
              icon: {
                element: 'img',
                attributes: {
                  width: 24,
                  height: 24,
                  src: cashRegisterIcon
                }
              }
            },
            {
              href: '/store/extract',
              title: 'Extrato',
              icon: {
                element: 'img',
                attributes: {
                  width: 24,
                  height: 24,
                  src: fileExtractIcon
                }
              }
            }
          ],
          icon: {
            element: 'img',
            attributes: {
              src: creditIcon
            }
          },
          hidden: false
        },
        help: {
          href: 'https://www.retextoar.com.br/faq-cliente/',
          external: true,
          title: 'Ajuda',
          icon: {
            element: 'img',
            attributes: {
              src: helpIcon
            }
          },
          hidden: false
        }
      };
    },
    components: {
      Header
    },
    computed: {
      ...mapState({
        account: state => state.account
      })
    },
    async created () {
      this.version = process.env.VUE_APP_VERSION;
      if (this.account.status.loggedIn) {
        await this.onUpdateMenus();
      }
    },
    methods: {
      ...mapActions('account', ['update', 'balance']),
      onToggleCollapse (collapsed) {
        this.collapsed = collapsed;
      },
      async onUpdateMenus () {
        const userInfo = await userService.userInfo();
        if (userInfo === 'Timeout') {
            this.isLoading = false;
            this.$toast.open({ message: 'Timeout de conexão!', type: 'error' });
            return;
          }
        if (userInfo && userInfo.organization && userInfo.organization.users) {
          const user = userInfo.organization.users.filter(user => { return user.login === userInfo.email; })[0];
          this.update({ master: !!userInfo.master, corporateId: user.corporateId, permissions: user.permissions, isAdmin: user.isAdmin });
        }
        this.onRecreateMenu(userInfo);
        await this.refreshBalance();
      },
      onItemClick (event, item) {},
      onRecreateMenu (userInfo) {
        this.menu = [];
        this.menu.push(this.home);
        if (userInfo && userInfo.organization) {
          this.menu.push(this.transcriptions);
          this.menu.push(this.users);
          // this.menu.push(this.groups);
        }
        this.menu.push(this.credits);
        this.menu.push(this.audit);
        this.menu.push(this.download);
        this.menu.push(this.help);
      },
      async refreshBalance () {
        await this.balance();
      },
      showErrorMessage () {
        const message = this.$store.state.alert.message;
        // TODO: Tests for using messagebox...
        this.$bvModal.msgBoxOk(message, {
          title: 'Erro',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'error',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
          okTitle: 'Fechar'
        });
        // this.$toast.open({ message: message, type: 'error' });
        this.$store.state.alert.hasError = false;
      }
    },
    watch: {
      $route (to, from) {
        if (from.path === '/login') {
          this.onUpdateMenus();
        }
        console.log(to.path);
        if (from.path === '/Editor' && (to.path === '/store/detached' || to.path === '/store/plans' || to.path === '/transcriptions')) {
          location.reload();
        }
        if (from.path === '/CorporateRegister' || from.path === '/signin') {
          location.reload();
          this.onUpdateMenus(from.params);
          this.$router.push('/home');
        }
        if (to.path === '/admin') {
          console.log('yes', this.$store.state.account);
          if (!this.$store.state.account.master) {
            this.$toast.open({ message: 'Nope!', type: 'error' });
            this.$router.push('/home');
          }
        }
      }
    }
  };
</script>

<style lang="scss">
  @import 'assets/scss/styles';

  .headerMenu {
    display: flex;
    align-items: center;
    margin-left: 27px;
    .logo-container {
      width: 200px;
      height: 70px;
      margin-left: -15px;
      margin-top: 0px;
      margin-bottom: 10px;
    }

    .title-style {
      margin: 0;
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 28px;
      font-weight: bold;
      color: #f2f7f4;
      text-decoration: none;
      transition: 0.5s;
    }

    .title-style:hover {
    font-family: Poppins;
    color: #fff;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #153252;
    }
  }

  .nav-left {
    background-color: #000;
  }
  #menu {
    padding-left: 200px;
  }

  #menu.collapsed {
    padding-left: 50px;
  }

  .menu {
    padding: 50px;
  }

  pre {
    font-family: Consolas, monospace;
    color: #000;
    background: #fff;
    border-radius: 2px;
    font-size: 13px;
    padding: 5px;
    overflow: auto;
  }

  #toasty-easter-egg {
    overflow: hidden;
    position: fixed;
    right: -10%;
    bottom: 0;
    transition: right 0.2s ease-in-out;
  }
</style>
