import config from '../config/config.json';
import { alert } from '../store/alert';
import { userService } from '../services/user_service';
import router from './router';

const statusError = {
  ERROR_401: 'Não autorizado!',
  ERROR_403: 'Acesso proibido!',
  ERROR_404: 'Não encontrado!',
  ERROR_500: 'Erro no servidor!',
  ERROR_SERVER: 'Erro no servidor!'
};

export function serverURL () {
  if (process.env.VUE_APP_SERVER_PORTAL) {
    return process.env.VUE_APP_SERVER_PORTAL;
  } else {
    return config.url;
  }
}

export async function fetchWithTimeout (resource, options = {}) {
  const { timeout = 8000 } = options;
  try {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal
    });
    clearTimeout(id);
    return response;
  } catch (e) {
    if (e.name === 'AbortError') {
      return { timeout: true };
    }
  }
}

async function handleError (res) {
  let message = 'Erro';
  let hasError = false;
  if (res.status === 401) {
    hasError = true;
    message = statusError.ERROR_401;
    userService.logout();
    await router.push('/login');
  } else if (res.status === 500) {
    hasError = true;
    message = statusError.ERROR_500;
  } else if (res.status === 403) {
    hasError = true;
    message = statusError.ERROR_403;
  } else if (res.status === 404) {
    hasError = true;
    message = statusError.ERROR_404;
  } else if (res === 'Error server') {
    hasError = true;
    message = statusError.errorServer;
  }
  alert.state.message = message;
  alert.state.hasError = hasError;
  return res.message;
}

async function handleResponse (response) {
  if (!response.ok) {
    return await handleError(response);
  } else {
    return await response.json();
  }
}

export async function handleRequest (url, options) {
  const res = await fetchWithTimeout(url, options);
  return await handleResponse(res);
}

export function formatingSecondToDate (value) {
  const h = Math.floor(value / 3600);
  const m = Math.floor(value % 3600 / 60);
  const s = Math.floor(value % 3600 % 60);
  const hDisplay = h < 0 ? '00' : h < 10 ? `0${h}` : h;
  const mDisplay = m < 0 ? '00' : m < 10 ? `0${m}` : m;
  const sDisplay = s < 0 ? '00' : s < 10 ? `0${s}` : s;

  return `${hDisplay}:${mDisplay}:${sDisplay}`;
}
