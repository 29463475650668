const state = {
  searchingWord: ''
};

const actions = {
  setSearchingWord ({ commit }, word) {
    commit('newSearchingWord', word);
  }
};

const mutations = {
  newSearchingWord (state, value) {
    state.searchingWord = value;
  }
};

const getters = {
  getSearchingWord (state) {
    return state.searchingWord;
  }
};

export const editor = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
;
