import { userService } from '../services/user_service';

const state = {
  type: null,
  message: null,
  hasError: false
};

const actions = {
  success ({ commit }, message) {
    commit('success', message);
  },
  error ({ commit }, message) {
    commit('error', message);
  },
  clear ({ commit }, message) {
    commit('success', message);
  },
  hasError ({ commit }, message) {
    commit('hasError', message);
  },
  logout ({ commit }) {
    userService.logout();
    commit('state = { type: null, message: null }');
  }
};

const mutations = {
  success (state, message) {
    state.type = 'alert-success';
    state.message = message;
  },
  error (state, message) {
    state.type = 'alert-danger';
    state.message = message;
  },
  clear (state) {
    state.type = null;
    state.message = null;
  },
  hasError (state, value) {
    state.hasError = value;
  }
};

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations
};
