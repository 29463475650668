const secretCode = 'ArrowUpArrowUpArrowDownArrowDownArrowLeftArrowRightArrowLeftArrowRightba';

let timer;
let charSequence = [];

window.addEventListener('keydown', event => {
  clearTimeout(timer);
  const keyPressed = event.key;

  charSequence.push(keyPressed);

  const charPhrase = charSequence.join('');
  if (charPhrase.includes(secretCode)) {
    charSequence = [];

    const toastyAudio = document.getElementById('toasy-audio');
    const toastyElement = document.getElementById('toasty-easter-egg');

    toastyElement.style.right = 0;
    toastyAudio.play();

    setTimeout(() => {
      toastyElement.style.right = '-10%';
    }, 1000);
  }

  timer = setTimeout(() => {
    charSequence = [];
  }, 1000);
});
